<template>
  <div>
    <!-- DESKTOP -->
    <div class="mb-xl-report-bonus-sign pl-8 pr-8">
      <v-row class="mt-11">
        <v-col class="text-left" cols="9">
          <p class="txt-title-report-bonus">
            Registro de documentos eliminados
          </p>
        </v-col>
      </v-row>
      <!-- Tabla -->
      <v-row>
        <v-col cols="12">
          <v-data-table
            :loading="loading.table"
            :items-per-page="10"
            :headers="headers"
            :items="items"
            item-key="name"
            class="elevation-1 table-report-header report-document__table"
            :footer-props="{ itemsPerPageOptions: [10, 15, 30] }"
          >
            <!-- Sin resultados de busqueda -->
            <template v-slot:no-results>
              <v-alert
                icon="mdi-information-outline"
                prominent
                text
                type="info"
              >
                <b>No se encontraron resultados</b>
              </v-alert>
            </template>
            <!-- Sin Data -->
            <template v-slot:no-data>
              <v-alert
                icon="mdi-information-outline"
                prominent
                text
                type="info"
              >
                <b>No se encontró información.</b>
              </v-alert>
            </template>
            <!-- Loading -->
            <template v-slot:progress>
              <v-progress-linear
                color="#214484"
                :height="5"
                indeterminate
                stream
              ></v-progress-linear>
            </template>
            <!-- Nombre -->
            <template v-slot:item.name="{ item }">
              <div class="float-left mt-2 pr-4">
                <img
                  style="border-radius: 120px"
                  width="35"
                  height="35"
                  :src="item.img"
                />
              </div>
              <div>
                <p class="mb-0 mt-2">{{ item.full_name }}</p>
                <p class="mb-0 mb-2">{{ item.document_number }}</p>
              </div>
            </template>
            <!-- estado -->
          </v-data-table>
        </v-col>
      </v-row>
    </div>

     <v-dialog v-model="openConfirm" width="410" persistent>
      <v-card style="border-radius: 15px">
        <v-card-title class="pt-6">
          <v-layout justify-center>
            <img
              class="ml-6"
              width="80"
              height="80"
              src="../../assets/icon/alert.svg"
            />
          </v-layout>
          <v-btn
            @click="openConfirm = false"
            color="#466BE3"
            icon
            x-small
            style="display: flex; left: 18px; bottom: 45px"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p class="document-managmet1__txt-dialog-title">¿Esta seguro</p>
          <p class="document-managmet1__txt-dialog-sms pb-0 mb-0">
            que desea anular el documento de este usuario?
          </p>
        </v-card-text>
        <v-card-actions class="pb-8">
          <v-row>
            <v-col cols="6">
              <v-btn
                block
                color="#466BE3"
                dark
                rounded
                @click="openConfirm = false"
                outlined
              >
                CERRAR
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                block
                color="#466BE3"
                dark
                rounded
                :loading="loadingBtn"
                @click="deleteDoc()"
              >
                Anular
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      @close="message.dialog = false"
    >
    </Alert>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        {{ $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
import color from "@/mixins/color";

export default {
  components: {
    Alert,
  },
  mixins: [color],
  name: "upload",
  data() {
    return {
      docId: null,
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
      },
      loadingAll: false,
      listDocuments: [],
      loading: {
        table: false,
      },
      items: [],
      menu2: false,
      headers: [
        {
          text: "Usuario",
          align: "start",
          value: "full_name",
          class: "report-document__header",
        },
        {
          text: "Documento",
          value: "nombre",
          class: "report-document__header",
        },
        {
          text: "Fecha de creación",
          value: "date_document",
          class: "report-document__header",
        },
        {
          text: "Fecha de borrado",
          value: "date_deleted",
          class: "report-document__header",
        },
        {
          text: "Borrado por",
          value: "fullname_del",
          class: "report-document__header",
        },
      ],
      token: "",
      user_id: null,
    };
  },
  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      // let currentUser = this.$store.getters.getUser;
      // this.currentUser = currentUser;


      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    download() {
      let data = {
        name: null,
        document_number: "",
        init_date: null,
        final_date: null,
      };
      if (this.form.name != null || this.form.name != "") {
        data.name = this.form.name;
      }
      if (
        this.form.document_number != null ||
        this.form.document_number != ""
      ) {
        data.document_number = this.form.document_number;
      }
      if (this.form.is_sign != null || this.form.is_sign != "") {
        if (this.form.is_sign != null && this.form.is_sign != "") {
          data.is_sign = this.form.is_sign == 2 ? 0 : 1;
        }
      }
      (data.init_date =
        this.form.date[0] != undefined ? this.form.date[0] : null),
        (data.final_date =
          this.form.date[1] != undefined ? this.form.date[1] : null),
        (this.loadingAll = true);

      Api.Auth()
        .managerDocumentsDownload(this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            this.message.dialog = true;
            this.message.sms = res.data.message;
            this.message.title = "Excelente";
            this.message.type = "success";
            window.open(res.data.data, "_blank");
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.message.dialog = true;
          if (error.response.status == 404) {
            this.message.sms =
              "Aun no se han cargado condiciones de comisiones";
            this.message.title = "¡Oh no!";
            this.message.type = "error";
          } else {
            this.message.sms = error.response.data.message;
            this.message.title = "¡Oh no!";
            this.message.type = "error";
          }
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    dinamicFilter() {
      this.user_id = sessionStorage.getItem("user_id");
      var data = {
        type : "juridica",
        area :'relaciones-laborales',
        user_id: this.user_id
      }
      this.items = [];
      this.loadingAll = true;
        Api.Auth()
          .filterDeleteDocumentsManager(this.token, data )
          .then((res) => {
            if (res.data.cod === 0) {
              this.items = res.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
            this.items = [];
          })
          .finally(() => {
            this.loadingAll = false;
          });
    },
  },
  created() {
    this.getToken();
  },
  mounted() {
    this.dinamicFilter();
  },
};
</script>
<style lang="less">
@import "./../../assets/css/main.less";
.mb-xl-report-bonus-sign {
  display: block;
}

.mb-sm-report-bonus-sign {
  display: none;
}

.txt-title-report-bonus {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.text-apps-blue-sm {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #466be3;
}
.text-apps-sm {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #626262;
  font-family: "AsapRegular";
}

.document-managmet1__txt-dialog-title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #353535;
}
.document-managmet1__txt-dialog-sms {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #898989;
}

.report-document__header {
  color: #353535 !important;
}

tr,
th,
td,
span .report-document__table .text-start .sortable {
  font-size: 15px !important;
  font-family: "RobotoRegular" !important;
}

.report-document__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 !important;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-report-bonus-sign {
    display: none;
  }
  .mb-sm-report-bonus-sign {
    display: block;
    text-align: initial;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  #app2 {
    background: #ffffff;
    border: 1px dashed #466be3;
    border-radius: 15px;
    height: 253px;
  }
  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-report-bonus-sign {
    display: none;
  }
  .mb-sm-report-bonus-sign {
    display: block;
    text-align: initial;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  #app2 {
    background: #ffffff;
    border: 1px dashed #466be3;
    border-radius: 15px;
    height: 253px;
  }
  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-report-bonus-sign {
    display: none;
  }
  .mb-sm-report-bonus-sign {
    display: block;
    text-align: initial;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  #app2 {
    background: #ffffff;
    border: 1px dashed #466be3;
    border-radius: 15px;
    height: 253px;
  }

  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-report-bonus-sign {
    display: none;
  }
  .mb-sm-report-bonus-sign {
    display: block;
    text-align: initial;
  }
}
</style>
